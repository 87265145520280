import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserPersistDto } from 'src/app/shared/models/user-persist-dto';
import { UsuarioPasswordDto } from 'src/app/shared/models/usuario-password-dto.model';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    constructor(private http: HttpClient) { }

    findAll(): Observable<any> {
        return this.http.get(`${environment.apiSecurityUrl}api/v1/usuarios`);
    }

    get(id: number): Observable<any> {
        return this.http.get(`${environment.apiSecurityUrl}api/v1/usuarios/${id}`);
    }

    findByUsername(username: string): Observable<any> {
        return this.http.get(`${environment.apiSecurityUrl}api/v1/usuarios?username=${username}`);
    }

    persist(userPersistDto: UserPersistDto): Observable<any> {
        return this.http.post(`${environment.apiSecurityUrl}api/v1/usuarios`, userPersistDto);
    }

    inactivateAccount(idUsuario: number): Observable<any> {
        return this.http.put(`${environment.apiSecurityUrl}api/v1/usuarios/${idUsuario}/inactive`, null);
    }
    resetPasswordAccount(idUsuario: number): Observable<any> {
        return this.http.put(`${environment.apiSecurityUrl}api/v1/usuarios/${idUsuario}/reset`, null);
    }

    checkToken(token: string): Observable<any> {
        return this.http.get(`${environment.apiSecurityUrl}api/v1/usuarios/token?token=${token}`);
    }

    updatePassword(token: string, dto: UsuarioPasswordDto): Observable<any> {
        return this.http.put(`${environment.apiSecurityUrl}api/v1/usuarios/${token}/password`, dto);
    }
}