import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Result } from 'src/app/shared/models/result';
import { OrganizacionDto } from 'src/app/shared/models/organizacion-dto.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizacionesService {

  constructor(private http: HttpClient) { }

  findAllActive(): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/organizaciones`);
  }

  get(id: number): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/organizaciones/` + id);
  }

  persist(obj: OrganizacionDto): Observable<Result> {
    return this.http.post<Result>(`${environment.apiSecurityUrl}api/v1/organizaciones`, obj)
  }

  update(id: number, obj: OrganizacionDto): Observable<Result> {
    return this.http.put<Result>(`${environment.apiSecurityUrl}api/v1/organizaciones/${id}`, obj);
  }

  delete(id: number): Observable<Result> {
    return this.http.delete<Result>(`${environment.apiSecurityUrl}api/v1/organizaciones/` + id);
  }

  activeItem(id: number): Observable<Result> {
    return this.http.put<Result>(`${environment.apiSecurityUrl}api/v1/organizaciones/` + id + `/active`, '');
  }

  getDocument(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.apiSecurityUrl}api/v1/organizaciones/` + id + `/download`, {headers, responseType: 'blob' as 'json'});
  }
}